import * as signalR from '@microsoft/signalr';
import { AuthorizeAction, AuthorizeResult } from '../auth';
import { EBEmitter } from '../emitter';

export class EBSignal {
  private connection: signalR.HubConnection | null = null;
  private policies: string[] = [];
  private authorizes: AuthorizeAction[] = [];

  get isEnabled() {
    return this.options.enabled;
  }

  get connectionId() {
    return this.connection?.connectionId;
  }

  constructor(
    private options: Readonly<{
      enabled: boolean;
      authorize: AuthorizeAction | AuthorizeAction[];
    }>,
    private emitter: EBEmitter
  ) {
    if (options.enabled) {
      this.policies = (
        options.authorize
          ? Array.isArray(options.authorize)
            ? options.authorize
            : [options.authorize]
          : []
      ).map((x) => x.policy);
      this.authorizes = Array.isArray(options.authorize) ? options.authorize : [options.authorize];

      emitter.on('eb-logged-in', () => {
        this.connect();
      });
      emitter.on('eb-logged-out', () => {
        const store = window.EB.storage.stateStore.getStore('signal', {
          isConnected: false,
          isConnecting: false,
          channels: []
        });

        store.channels = [];

        if (this.authorizes.every((x) => window.EB.auth.authorize(x) !== AuthorizeResult.Allow)) {
          this.disconnect();
        }
      });
    }
  }

  async connect() {
    if (
      !this.authorizes.length ||
      this.authorizes.every((x) => window.EB.auth.authorize(x) !== AuthorizeResult.Allow) ||
      this.connection?.state === signalR.HubConnectionState.Connected
    ) {
      return;
    }

    const store = window.EB.storage.stateStore.getStore('signal', {
      isConnected: false,
      isConnecting: false,
      channels: []
    });

    store.isConnecting = true;

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`/eb-signal?app=${window.EB.appName}&lang=${window.EB.intl.selectedLang}`, {
        transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          console.error('Signal reconnect error', retryContext.retryReason);

          if (retryContext.previousRetryCount >= 12) {
            // TODO: should we show a toast for this case? .i.e trying to reconnect for 1 minute
          }

          return 5000;
        }
      })
      .build();

    try {
      await this.connection.start();

      this.emitter.emit('eb-signal-connected');
      store.isConnected = true;
      store.isConnecting = false;

      if (store.channels.length) {
        this.connection.send('SubscribeChannel', await this.getHeaders(), store.channels);
      }

      this.connection.onreconnected(async () => {
        this.emitter.emit('eb-signal-reconnected');
        store.isConnected = true;
        store.isConnecting = false;

        if (store.channels.length) {
          this.connection?.send('SubscribeChannel', await this.getHeaders(), store.channels);
        }
      });

      this.connection.onreconnecting(() => {
        this.emitter.emit('eb-signal-reconnecting');
        store.isConnecting = true;
      });

      this.connection.on(
        'ReceiveSignal',
        (name: string, data: object, channel: string, connectionId: string) => {
          if (name === 'disconnect') {
            this.connection?.stop();
            return;
          }

          this.emitter.emit('eb-signal-received', {
            name,
            data,
            channel,
            isFromCurrentConnection: connectionId === this.connectionId
          });
        }
      );

      this.connection.onclose(() => {
        this.emitter.emit('eb-signal-disconnected');
        store.isConnected = false;
        store.isConnecting = false;
      });
    } catch (error) {
      console.error('Signal connection fail', error);
    }
  }

  private getHeaders = async () => {
    return window.EB.httpClient.buildAuthHeadersForAllPolicies(this.policies).then((headers) => {
      return headers.reduce((acc, header) => {
        if (header) {
          acc[header.key] = header.value;
        }
        return acc;
      }, {} as { [key: string]: string });
    });
  };

  async subscribe(channels: string[]) {
    if (!this.connection) {
      return;
    }

    const store = window.EB.storage.stateStore.getStore<{
      isConnected: boolean;
      isConnecting: boolean;
      channels: string[];
    }>('signal', {
      isConnected: false,
      isConnecting: false,
      channels: []
    });

    if (this.connection.state === signalR.HubConnectionState.Connected) {
      this.connection.send('SubscribeChannel', await this.getHeaders(), channels);
    }

    store.channels.push(...channels);
  }

  async unsubscribe(channels: string[]) {
    if (!this.connection) {
      return;
    }

    const store = window.EB.storage.stateStore.getStore<{
      isConnected: boolean;
      isConnecting: boolean;
      channels: string[];
    }>('signal', {
      isConnected: false,
      isConnecting: false,
      channels: []
    });

    if (this.connection.state === signalR.HubConnectionState.Connected) {
      this.connection.send('UnsubscribeChannel', await this.getHeaders(), channels);
    }

    store.channels = store.channels.filter((x) => !channels.includes(x));
  }

  disconnect() {
    if (!this.connection) {
      return;
    }

    this.connection.stop();
  }
}
