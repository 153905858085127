import { EBAssets } from './assets';
import { EBAuth } from './auth';
import { AppType, EBGlobalConfig, HttpClient } from './common';
import { EBDatabase } from './database';
import { EBEmitter } from './emitter';
import { EBIntl } from './languages';
import { EBStorage } from './storage';
import { EBCache } from './storage/cache';
import { EBTask } from './task';
import { EBTheme } from './theme';
import { EBSignal } from './signal';
import utils from './utils';

export class EBGlobal<
  ConstantType = Record<string, unknown>,
  ExternalType = Record<string, unknown>
> {
  appName: string;
  appTitle: string;
  httpClient: HttpClient;
  appType: AppType;
  db: EBDatabase;
  intl: EBIntl;
  emitter: EBEmitter;
  task: EBTask;
  auth: EBAuth;
  storage: EBStorage;
  assets: EBAssets;
  theme: EBTheme;
  signal: EBSignal;
  cache: EBCache;
  externals: ExternalType;
  constant: ConstantType;
  utils: typeof utils;

  constructor(config: EBGlobalConfig) {
    this.appName = config.appName;
    this.appTitle = config.appTitle;
    this.appType = config.appType;
    this.httpClient = new HttpClient(config);
    this.constant = (config.constant ?? {}) as ConstantType;
    this.externals = (config.externals ?? {}) as ExternalType;
    this.db = new EBDatabase(this.httpClient);
    this.storage = new EBStorage();
    this.assets = new EBAssets(config.cacheVersion, config.appName, config.appType);
    this.auth = new EBAuth(this.httpClient, config.securityPolicies);
    this.task = new EBTask(this.httpClient);
    this.intl = new EBIntl(config.languages);
    this.cache = new EBCache();
    this.emitter = new EBEmitter(
      !localStorage.getItem('__eb_emitter_log_enabled')
        ? import.meta.env.DEV
        : localStorage.getItem('__eb_emitter_log_enabled') === 'true',
      this.db,
      this.task,
      this.intl
    );
    this.theme = new EBTheme(config.appName, config.theme);
    this.signal = new EBSignal(config.signal ?? { enabled: false, authorize: [] }, this.emitter);
    this.utils = utils;
  }

  onAsyncLoadError = (
    error: Error,
    retry: () => void,
    fail: () => void,
    attempts: number
  ): void => {
    if (error.message.match(/fetch/) && attempts <= 3) {
      // retry on fetch errors, 3 max attempts
      retry();
    } else {
      // Note that retry/fail are like resolve/reject of a promise:
      // one of them must be called for the error handling to continue.
      fail();

      window.EB.emitter
        .alert(window.EB.intl.formatMessage('__eb_async_load_error'), 'error')
        .then(() => {
          window.location.reload();
        });
    }
  };
}

export const createEBApp = (config: EBGlobalConfig): EBGlobal => new EBGlobal(config);

export * from './auth';
export * from './common';
export * from './database';
export * from './emitter';
export * from './storage';

window.createEBApp = createEBApp;

declare global {
  interface Window {
    /**
     * This global variable is initiated in generated/app.*.js file.
     * Please make sure you handle this in testing.
     */
    EB: EBGlobal;
    createEBApp: typeof createEBApp;
  }
}
